@import url(https://fonts.googleapis.com/css2?family=Libre+Baskerville&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  font-family: "Libre Baskerville", serif;
}
* a {
  text-decoration: none;
  color: #7d503e;
  /* For Safari 3.0 to 6.0 */
  transition: color 0.3s, font-size ease-in-out 0.3s;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  will-change: transform;
}
* button {
  text-decoration: none;
  background: none;
  border: none;
}

.App {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  background-color: #f6efe6;
  color: #3f3f3f;
  min-height: 100vh;
}

.Smile {
  font-size: 3vw;
  display: inline-block;
  color: #3f3f3f;
}

.Body {
  font-size: 3vw;
  width: 28vw;
  visibility: hidden;
  margin-left: 1vw;
}

.BodyTextPhrase {
  margin: 2vh;
}

.HelloText {
  display: inline-block;
}

.CommaText {
  display: inline-block;
  color: #3f3f3f;
}

.PeriodText {
  display: inline-block;
  color: #3f3f3f;
}

.Parentheses {
  display: inline-block;
  color: #3f3f3f;
}

.Name:hover {
  color: #ffd500;
  font-size: 105%;
}

.Facebook:hover {
  color: #436bee;
  font-size: 105%;
}

.Github:hover {
  color: #ff0054;
  font-size: 105%;
}

.Resume:hover {
  color: #4b00ce;
  font-size: 105%;
}

.LinkedIn:hover {
  color: #c200a1;
  font-size: 105%;
}

.shake:hover {
  -webkit-animation: shake 75ms infinite;
          animation: shake 75ms infinite;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
}

@-webkit-keyframes shake {
  0% {
    -webkit-transform: rotate(0.5deg);
            transform: rotate(0.5deg);
  }
  50% {
    -webkit-transform: rotate(-0.5deg);
            transform: rotate(-0.5deg);
  }
  100% {
    -webkit-transform: rotate(0.5deg);
            transform: rotate(0.5deg);
  }
}

@keyframes shake {
  0% {
    -webkit-transform: rotate(0.5deg);
            transform: rotate(0.5deg);
  }
  50% {
    -webkit-transform: rotate(-0.5deg);
            transform: rotate(-0.5deg);
  }
  100% {
    -webkit-transform: rotate(0.5deg);
            transform: rotate(0.5deg);
  }
}
@media (max-width: 500px) {
  .App {
    margin-top: -100px;
  }

  .Body {
    width: 80vw;
    font-size: 35px;
    padding: 20px 5px;
  }

  .Smile {
    font-size: 35px;
  }
}

/*# sourceMappingURL=App.css.map */

